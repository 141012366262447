import { useMemo } from 'react';

import { type EditorClassificationTagWrapperProps } from '../EditorComponentsV2/EditorClassificationTagWrapper';

import { useIsClassificationEnabledForContentTypeExpansion } from './useIsClassificationEnabledForContentTypeExpansion';
import { useIsEllipsisClassifyEnabled } from './useIsEllipsisClassifyEnabled';

export const useShouldRenderEditorActionBarClassificationPlaceholder = (
	props: EditorClassificationTagWrapperProps,
): boolean => {
	const {
		isDataClassificationEnabledForOrg,
		contentClassificationMetadata,
		hasEditPermission,
		contentType,
		isLivePage,
	} = props;

	const { isEllipsisClassifyEnabled } = useIsEllipsisClassifyEnabled();
	const isClassificationEnabledForContentTypeExpansion =
		useIsClassificationEnabledForContentTypeExpansion(contentType, isLivePage);

	const { hasNoClassification, contentLevelLoading, spaceLevelLoading } =
		contentClassificationMetadata;

	// Note: these "oldConditions" can be removed once the `dlp_cc-ellipsis-classify-content` feature gate is removed
	const oldConditions = useMemo(() => {
		if (!isDataClassificationEnabledForOrg) return false;

		const isClassificationLevelDisabledForLivePage =
			isLivePage && !isClassificationEnabledForContentTypeExpansion;

		const shouldNotLoadClassificationPlaceholder =
			isLivePage && !hasEditPermission && hasNoClassification;

		// Primary Conditions
		// Display a placeholder during SSR when user doesn't have edit perms and page does have a data classification
		const withClassificationNoEditPermsSSR =
			isDataClassificationEnabledForOrg &&
			process.env.REACT_SSR &&
			!hasEditPermission &&
			!hasNoClassification;
		// Display a placeholder during SSR when user has edit perms
		const withEditPermsSSR =
			isDataClassificationEnabledForOrg && process.env.REACT_SSR && hasEditPermission;
		// Display a placeholder for classification to prevent cls
		const withLoadingClassification =
			(contentLevelLoading || spaceLevelLoading) &&
			!shouldNotLoadClassificationPlaceholder &&
			!isClassificationLevelDisabledForLivePage;
		// Represents logic passed as prop to EditorActionBarWrapper
		const hasLozengeInActionBarWrapper =
			!isClassificationLevelDisabledForLivePage && !shouldNotLoadClassificationPlaceholder;

		return (
			withClassificationNoEditPermsSSR ||
			withEditPermsSSR ||
			withLoadingClassification ||
			hasLozengeInActionBarWrapper ||
			false
		);
	}, [
		isLivePage,
		isClassificationEnabledForContentTypeExpansion,
		hasEditPermission,
		hasNoClassification,
		isDataClassificationEnabledForOrg,
		contentLevelLoading,
		spaceLevelLoading,
	]);

	const newConditions = useMemo(() => {
		if (!isDataClassificationEnabledForOrg) return false;
		if (hasNoClassification) return false;
		if (contentLevelLoading || spaceLevelLoading) return false;
		return true;
	}, [
		contentLevelLoading,
		hasNoClassification,
		isDataClassificationEnabledForOrg,
		spaceLevelLoading,
	]);

	if (isEllipsisClassifyEnabled) {
		return newConditions;
	} else {
		return oldConditions;
	}
};
