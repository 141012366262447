import React, { useCallback, useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import ErrorIcon from '@atlaskit/icon/utility/migration/error';
import { Flex, Stack, Text, xcss } from '@atlaskit/primitives';
import Select, {
	type OptionProps,
	type OptionType,
	components as SelectComponents,
	type SingleValueProps,
	type StylesConfig,
} from '@atlaskit/select';
import { token, useThemeObserver } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { colorsConfig, disabledColorConfig, nameMaxLength } from '../../constants';
import type { ClassificationOption } from '../../types';
import { ClassificationIcon } from '../classification-icon';

import {
	ContainerIcon,
	ContainerLabel,
	Definition,
	DropdownLabel,
	Name,
	NameLabel,
} from './styled';
import { type DataClassificationDropdownProps } from './types';

/**
 * The options in the select dropdown are quite customizable, and achieves different use-cases.
 * Options are identified (i.e. how an option in the dropdown is selected) by the "name", "label", and "definition" properties.
 * Typeof "option" prop is "ClassificationOption", which has requires at least one of the properties to exist, therefore it is safe to assume that an option will always be identifiable.
 * Obviously there can be duplicates, but that is a user error.
 *
 * Since only one of these identifying properties is required, there are 7 different combinations of these properties that will render a different option.
 *
 * (1) name
 *    - renders: name with icon (if includeIcon is true) in the icon container
 * (2) name, label
 *    - renders: name with icon (if includeIcon is true) in the icon container, and an inline label outside of the icon container
 * (3) name, definition
 *    - renders: name with icon (if includeIcon is true) in the icon container, and a definition below the icon container
 * (4) name, label, definition
 *    - renders: name with icon (if includeIcon is true) in the icon container, an inline label outside of the icon container, and a definition below the icon container
 * (5) label
 *    - renders: icon container with only an icon, and an inline label outside of the icon container
 *    - note: if includeIcon is false, then the icon container will not render (i.e. only an inline label will render)
 * (6) label, definition
 *    - renders: similar to (5) but with a definition below the icon container
 * (7) definition
 *    - renders: icon container with only an icon (conditionally rendered based on includeIcon), and a definition below the icon container
 *
 * Use cases:
 *    For space/project defaults we don't want to render a name.
 *    Instead we want to render a label: "Use space/project default".
 *    We also want to render a definition defining what the space/project default is.
 *    In this case, we'll use (6).
 *
 *    For resetting a classification (i.e. selecting the "No classification" option), we don't want to render a name or label.
 *    Instead we want to render only a definition and without an icon.
 *    In this case, we'll use (7) and pass in includeIcon={false}.
 */

const dangerTextStyles = xcss({
	color: 'color.text.danger',
	paddingInlineStart: 'space.025',
});

const CustomSingleValue = ({ children, ...props }: SingleValueProps<OptionType, false>) => {
	const { colorMode = 'light' } = useThemeObserver();

	const { data } = props;
	const {
		name,
		nameLabel,
		definition,
		color = 'GREY',
		includeIcon = true,
		classificationOverride,
		nameFormatter,
	} = (data as Partial<ClassificationOption & { nameLabel: string }>) || {};

	const inputName = classificationOverride?.name ?? name ?? nameLabel ?? definition;

	return (
		<SelectComponents.SingleValue {...props}>
			<ContainerLabel>
				{nameFormatter ? (
					nameFormatter(data)
				) : (
					<ContainerIcon
						colorsConfig={colorsConfig[colorMode][color]}
						isIconOnly={includeIcon && !inputName}
					>
						{includeIcon && (
							<ClassificationIcon
								width={14}
								height={14}
								isDisabled={false}
								color={colorsConfig[colorMode][color].fontColor}
								disabledColorConfig={disabledColorConfig[colorMode]}
							/>
						)}
						{inputName && <Name>{inputName}</Name>}
					</ContainerIcon>
				)}
			</ContainerLabel>
		</SelectComponents.SingleValue>
	);
};
/**
 * CustomOption component is used to render each option in the dropdown.
 **/
const CustomOption = (props: OptionProps) => {
	const { data } = props;
	const {
		name,
		definition,
		nameLabel,
		includeIcon = true,
		nameFormatter,
	} = (data as Partial<ClassificationOption & { nameLabel: string }>) || {};

	return (
		<SelectComponents.Option {...props}>
			<Stack space="space.050">
				{/*
          If no name or nameLabel supplied, then don't render ContainerLabel.
          This makes it possible to display an option that only renders a definition.
        */}
				{(name || nameLabel || includeIcon) && (
					<ContainerLabel definitionExists={Boolean(definition)}>
						{nameFormatter ? nameFormatter(data) : <ClassificationLozenge {...data} />}
						{nameLabel && <NameLabel>{nameLabel}</NameLabel>}
					</ContainerLabel>
				)}
				{definition && <Definition>{definition}</Definition>}
			</Stack>
		</SelectComponents.Option>
	);
};

export const ClassificationLozenge = (data: ClassificationOption) => {
	const { colorMode = 'light' } = useThemeObserver();
	const {
		name,
		nameLabel,
		includeIcon = true,
		color = 'GREY',
		colorOnlyIconBackground = false,
	} = (data as Partial<ClassificationOption & { nameLabel: string }>) || {};

	// if name and label are both present, trim name to fit nameLabel in the same row
	const shouldTrimBothNameAndLabel = Boolean(name && nameLabel);

	const iconComponent = includeIcon && (
		<ClassificationIcon
			width={14}
			height={14}
			isDisabled={false}
			color={colorsConfig[colorMode][color].fontColor}
			disabledColorConfig={disabledColorConfig[colorMode]}
		/>
	);

	/* name inside a lozenge will only be a string and not a reactNode */
	const nameComponent = name && (
		<Name>{name.substring(0, nameMaxLength / (shouldTrimBothNameAndLabel ? 2 : 1))}</Name>
	);

	return colorOnlyIconBackground ? (
		<>
			<ContainerIcon
				colorsConfig={colorsConfig[colorMode][color]}
				isIconOnly={includeIcon && !name}
			>
				{iconComponent}
			</ContainerIcon>
			{nameComponent}
		</>
	) : (
		<ContainerIcon colorsConfig={colorsConfig[colorMode][color]} isIconOnly={includeIcon && !name}>
			{iconComponent}
			{nameComponent}
		</ContainerIcon>
	);
};

export const DataClassificationDropdown = <ExtendedType,>({
	label,
	options,
	value,
	errorMessage,
	disabledTooltipMessage,
	isDisabled,
	onChange,
	onInputChange,
}: DataClassificationDropdownProps<ExtendedType>) => {
	// find selected classification in options
	const onSelectChange = (option: OptionType | null) => {
		// we want to identify the selected option by these properties
		const selectedOption = {
			name: option?.name,
			definition: option?.definition,
			label: option?.nameLabel,
		};

		// find corresponding classification in options based on identifying properties
		const selectedClassification = options.find((opt) =>
			isEqual(
				{
					name: opt.name,
					definition: opt.definition,
					label: opt.label,
				},
				selectedOption,
			),
		);

		if (!selectedOption || !selectedClassification) {
			return;
		}

		if (onChange) {
			onChange(selectedClassification);
		}
	};

	/*
    Since select component requires label & value props in list of options, we'll transform "options" prop to include these values.
  */
	const transformToOptionType = useCallback(
		(classification: ClassificationOption<ExtendedType>) => ({
			...cloneDeep(classification),
			// either name, label, or definition must exist, so we can safely use ! here
			label: (classification.name ?? classification.label ?? classification.definition)!,
			// Asserting this as a string to keep lint happy. This wont be an issue, since CustomOption component will handle scenarios where name is a ReactNode.
			value: ((classification.name as string) ??
				classification.label ??
				classification.definition)!,
			nameLabel: classification.label, // since label is reserved for an option, we'll use nameLabel
		}),
		[],
	);

	const transformedOptions = useMemo(
		() => options.map(transformToOptionType),
		[options, transformToOptionType],
	);
	const renderDropdown = () => (
		<Select
			label="data-classification-select"
			inputId="data-classification-dropdown-input"
			options={transformedOptions}
			value={!value || errorMessage ? null : transformToOptionType(value)}
			onChange={onSelectChange}
			onInputChange={onInputChange}
			isInvalid={Boolean(errorMessage)}
			isDisabled={isDisabled}
			components={{
				Option: CustomOption,
				SingleValue: CustomSingleValue,
			}}
			styles={
				{
					option: (baseStyles) => ({
						...baseStyles,
						padding: token('space.200', '16px'),
					}),
					menuPortal: (base) => ({ ...base, zIndex: 9999 }),
				} as StylesConfig<OptionType, false>
			}
			menuPortalTarget={document.body}
		/>
	);
	return (
		<Stack space="space.100">
			{label && <DropdownLabel htmlFor="data-classification-dropdown-input">{label}</DropdownLabel>}
			<Stack space="space.050">
				{isDisabled ? (
					<Tooltip content={disabledTooltipMessage}>{renderDropdown()}</Tooltip>
				) : (
					renderDropdown()
				)}
				{errorMessage && (
					<Flex alignItems="center" gap="space.050" xcss={dangerTextStyles}>
						<ErrorIcon
							color="currentColor"
							LEGACY_size="small"
							LEGACY_margin={`0 ${token('space.negative.025')}`}
							label=""
						/>
						<Text as="p" size="small" color="inherit">
							{errorMessage}
						</Text>
					</Flex>
				)}
			</Stack>
		</Stack>
	);
};
